<template>
  <!-- 화면ID : CSCT030G010 -->
  <div class="content">
    <!-- content : 화면 리사이징 되는 화면 resize 추가 -->
    <e-breadcrumbs />
    <h1 class="page_title">{{ $t('menu.MENU09.030') }}</h1> <!-- 자료실 -->

    <!-- 검색 박스 -->
    <div v-if="!toggleValue" class="content_box mt10">
      <div class="mt5">
        <select name="catCd" id="" class="wid110" v-model="form.catCd">
          <option value="all" selected>{{ $t('msg.PERS010L022.002') }}</option>
          <option v-for="(item,index) in categoryCdList.cdId" :value="item.cd" :key="index">
            {{ $t(`code.WB004.${item.cd}`) }}
          </option>
        </select>
        <select name="select" id="" class="wid110" v-model="form.select">
          <option value="all">{{ $t('msg.CMBA100.00117') }}+{{ $t('msg.CMBA100.00119') }}</option> <!-- 제목+내용 -->
          <option value="title">{{ $t('msg.CMBA100.00117') }}</option> <!-- 제목 -->
          <option value="contents">{{ $t('msg.CMBA100.00119') }}</option> <!-- 내용 -->
        </select>
        <input type="text" id="" name="searchInput" class="wid300" v-model="form.searchInput" @keyup.enter="search">
        <a class="button blue sh ml15" @click.prevent="search">{{ $t('msg.CMBA100.528') }}</a> <!-- 검색 -->
      </div>
    </div>

    <!-- 글 리스트 카운트 및 목록 -->
    <div class="content_box mt10">
      <BoardsDetail v-if="toggleValue" :detail="this.detail" />

      <!-- 글 개수 카운트 -->
      <div class="flex_box" :class="[toggleValue ? 'mt30': '']">
        <span class="tot_num">{{ $t('msg.CMBA100.530') }} : <span class="num">{{ listCountNumber }}</span></span> <!-- Total -->
      </div>

      <!-- 글 리스트 -->
      <table class="tbl_list font_sm">
        <colgroup>
          <col width="60px">
          <col width="80px">
          <col width="*">
          <col width="50px">
          <col width="100px">
          <col width="80px">
          <col>
        </colgroup>
        <thead>
          <tr>
            <th>{{ $t('msg.CMBA100.531') }}</th> <!-- 번호 -->
            <th>{{ $t('msg.CMBA100.527') }}</th> <!-- 구분 -->
            <th>{{ $t('msg.CMBA100.00117') }}</th> <!-- 제목 -->
            <th>{{ $t('msg.CMBA100.00141') }}</th> <!-- 첨부 -->
            <th>{{ $t('msg.CMBA100.00126') }}</th> <!-- 등록일 -->
            <th>{{ $t('msg.CMBA100.00127') }}</th> <!-- 조회수 -->
          </tr>
        </thead>
        <tbody v-if="items.length > 0">
          <tr v-for="(item,index) in items" :key="index">
            <td class="no"><span>{{ item.num }}</span></td>
            <td class="category">
              {{ $ekmtcCommon.isNotEmpty(item.cd) ? $t(`code.WB004.${item.cd}`) : '' }}
            </td>
            <td class="subject">
              <a :id="item.messageIdSeq" :class="toggleValue === item.messageIdSeq ? 'select' : ''" @click="moveDetail(item.messageIdSeq, 'C', item.groupId, item.catNm, item.ctrCd)">{{ item.title }}</a><span v-if="item.isNew" class="tbl_icon new"></span>
            </td>
            <td><a v-if="item.fileName" class="tbl_icon attach" @click.prevent="moveDetail(item.messageIdSeq, 'C', item.groupId, item.catNm, item.ctrCd)">첨부</a></td>
            <td class="date">{{ $ekmtcCommon.changeDatePattern(item.createDate, '-') }}</td> <!-- 인자가 있는 경우 computed보다 method 권장 -->
            <td class="hits">{{ item.hitCount }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="7">{{ $t('msg.CMBA100.00131') }}</td> <!-- 등록된 내용이 없습니다. -->
          </tr>
        </tbody>
      </table>

      <!-- paging -->
      <div v-if="items.length > 0" class="paging">
        <a @click.prevent="getBoardsList(1, false)"
           class="btn start"
        >Start</a>
        <a @click.prevent="getBoardsList(pagination.prevPage, false)"
           class="btn prev"
        >Prev</a>
        <a v-for="idx in pageNumberRange"
           :key="idx"
           :class="[idx === form.curPage ? 'on' : '']"
           @click.prevent="getBoardsList(idx, false)"
        >{{ idx }}</a>
        <a @click.prevent="getBoardsList(pagination.nextPage, false)"
           class="btn next"
        >Next</a>
        <a @click.prevent="getBoardsList(pagination.pageCnt, false)"
           class="btn end"
        >End</a>
      </div>
    </div>

    <!-- 목록 버튼 -->
    <div v-if="toggleValue" class="mt10 text_right">
      <a class="button blue lg" @click.prevent="toggleChange('')">{{ $t('msg.CMBA100.529') }}</a> <!-- 목록 -->
    </div>
  </div>
</template>

<script>
import boards from '@/api/services/boards'
import BoardsDetail from '@/pages/common/BoardsDetail'
import EBreadcrumbs from '@/components/common/EBreadcrumbs'
import { rootComputed } from '@/store/helpers'

var eventFunc
window.onpopstate = function (event) {
  eventFunc = event
}

export default {
  name: 'Pds',
  components: {
    BoardsDetail,
    EBreadcrumbs
   },
  data: function () {
    return {
      form: {
        curPage: 1,
        pageSize: 15,
        tableId: 'C',
        catCd: 'all',
        select: 'all',
        searchInput: '',
        title: '',
        contents: '',
        serviceCtrCd: ''
      },
      listCountNumber: 0,
      items: [],
      pagination: {},
      detail: [{ catCd: '' }],
      toggleValue: '',
      categoryCdList: [],
      handlebackBtn: true,
      pageHistory: [],
      pageCnt: 0,
      backBtn: false
    }
  },
  computed: {
    ...rootComputed,
    pageNumberRange: function () {
      return [...Array(this.pagination.pageNumberSize).keys()].map(i => i + this.pagination.startPage)
    }
  },
  mounted () {
    $('.header').click(function (e) {
      // console.log('header click @@@@@')
      vmApp.handlebackBtn = false
    })

    $('.quick_wrap').click(function (e) {
      // console.log('quick_wrap click @@@@@')
      vmApp.handlebackBtn = false
    })

    $('.content').click(function (e) {
      // console.log('content click @@@@@')
      vmApp.handlebackBtn = true
    })
  },
  beforeRouteLeave (to, from, next) {
    if (eventFunc !== undefined) {
        eventFunc = undefined
    }

    var pageLen = this.pageHistory.length
    var bfPage = pageLen - 2
    var curPage = pageLen - 1

    setTimeout(() => {
      window.onpopstate = function (event) {
        eventFunc = event
      }
    }, 200)

    setTimeout(() => {
      if (eventFunc !== undefined) {
        if (!this.$ekmtcCommon.isEmpty(this.toggleValue) && this.handlebackBtn) {
          history.pushState(null, null, location.pathname + '#/common/pds')
          this.toggleChange('')
        } else {
          if (pageLen > 1 && this.handlebackBtn) {
              history.pushState(null, null, location.pathname + '#/common/pds')

              // 공지사항 안에서 이전페이지 이동
              if (this.pageHistory[bfPage] !== undefined) {
                if (this.pageHistory[bfPage].searchInput !== '') {
                  this.form.searchInput = this.pageHistory[bfPage].searchInput
                  this.backSearch(this.pageHistory[bfPage].page, true)
                } else {
                  this.form.searchInput = ''
                  this.backSearch(this.pageHistory[bfPage].page, false)
                }

                this.toggleChange('')

                this.pageHistory.splice(Number(curPage), 1)
              }
          } else {
            next()
          }
        }
      } else {
        if (!this.$ekmtcCommon.isEmpty(this.toggleValue) && this.handlebackBtn) {
          history.pushState(null, null, location.pathname + '#/common/pds')
          this.toggleChange('')
        } else {
          if (pageLen > 1 && this.handlebackBtn) {
              history.pushState(null, null, location.pathname + '#/common/pds')

              // 공지사항 안에서 이전페이지 이동
              if (this.pageHistory[bfPage] !== undefined) {
                if (this.pageHistory[bfPage].searchInput !== '') {
                  this.form.searchInput = this.pageHistory[bfPage].searchInput
                  this.backSearch(this.pageHistory[bfPage].page, true)
                } else {
                  this.form.searchInput = ''
                  this.backSearch(this.pageHistory[bfPage].page, false)
                }

                this.toggleChange('')

                this.pageHistory.splice(Number(curPage), 1)
              }
          } else {
            next()
          }
        }
      }
    }, 300)
  },
  created () {
    window.vmApp = this

    // 접속국가 가져오기
    if (this.auth.login) {
      this.form.serviceCtrCd = this.auth.userCtrCd
    } else {
      //
      if (localStorage.getItem('service_ctrcd') === 'EN') {
        this.form.serviceCtrCd = 'AE'
      } else {
        this.form.serviceCtrCd = localStorage.getItem('service_ctrcd')
      }
    }
    // console.log('@@@@ this.$route.query: ', this.$route.query)

    if (Object.keys(this.$route.query).length > 0) {
      const vo = this.$route.query

      // 통합검색 결과 페이지에서 들어오는 경우 - 상세 페이지까지 진입해야 함.
      if (vo.searchInput !== undefined && this.$ekmtcCommon.isNotEmpty(vo.searchInput)) {
        this.form.searchInput = vo.searchInput

        this.form.title = this.form.searchInput
        this.form.contents = this.form.searchInput
      }

      this.getCode({ cdId: 'WB004' })
      if (vo.num !== undefined && this.$ekmtcCommon.isNotEmpty(vo.num)) {
        this.getBoardsList(Math.ceil(Number(vo.num) / Number(this.form.pageSize)), true)
      } else {
        this.getBoardsList(1, true)
      }

      this.moveDetail(vo.messageIdSeq, 'C', vo.groupId, vo.catNm, vo.ctrCd)
    } else {
      //공통코드 불러오기
      this.getCode({ cdId: 'WB004' })
      //리스트 불러오기
      this.getBoardsList(1) // tweum 변경 (parameter json => index )
    }
  },
  methods: {
    //공통코드 호출
    getCode (formData) {
      boards.getCode(formData).then((data) => {
        //카테고리
        this.categoryCdList.push(formData.cdId)
        this.categoryCdList.cdId = data.data
      })
    },
    //게시글 조회 기본 메소드
    // tweum 변경 (parameter json => index )
    /**
     * param
     * |_ index : 이동할 페이지
     * |_ searcgFlag : 검색유무
     *          true  => 검색 시
     *          false => 페이지 이동
     */
    getBoardsList (index, searchFlag, backFlag) {
      if (this.$ekmtcCommon.isEmpty(backFlag)) {
        const data = {
          idx: this.pagination.pageCnt,
          page: index,
          searchInput: this.form.searchInput
        }
        this.pageHistory.push(data)
        this.backBtn = false
      } else {
        this.backBtn = true
      }

      if (Object.keys(this.$route.query).length > 0) {
        this.$router.replace({ query: {} })
      }

      const alertOpt = {
        alertType: 'simple',
        customCloseBtnText: this.$t('msg.CMBA100.546'), // 닫기
        useConfirmBtn: false
      }

      if (this.isInit && index < 1) {
        this.$ekmtcCommon.alert({
          ...alertOpt, message: this.$t('js.common.002') // 처음페이지입니다.
        })
        return
      }
      if (this.isInit && this.pagination && index > this.pagination.pageCnt && !this.backBtn) {
        this.$ekmtcCommon.alert({
          ...alertOpt, message: this.$t('js.common.001') // 마지막페이지입니다.
        })
        return
      }

      // !searchFlag(페이지 이동 시) 현재 페이지와 이동할 페이지 같다면
      if (!searchFlag && this.isInit && this.form.curPage === index) return

      this.form.curPage = index

      let _form = {}

      //if (this.auth.login === false && localStorage.getItem('service_ctrcd') === 'EN') {
      if (localStorage.getItem('service_ctrcd') === 'EN') {
        _form = {
          ...this.form,
          serviceCtrCd: 'DUMMY'
        }
      } else {
        _form = {
          ...this.form,
          serviceCtrCd: localStorage.getItem('service_ctrcd')
        }
      }

      boards.getBoardsList(_form).then((data) => {
        this.items = []
        const list = data.data.boardList

        //전체 글 개수
        if (list !== undefined && list.length > 0) {
          this.listCountNumber = list.length === 0 ? 0 : list[0].listCnt

          list.forEach((item) => {
              //new icon 타이틀 여부 체크 후 리스트에 담기
              item.isNew = this.isNewCheck(item.createDate)
              item.catNm = this.getCategoryName(item.catCd)

              this.items.push(item)
          })

          this.pagination = data.data.pagination

          if (!this.isInit) this.isInit = true
        }
      })
      .catch(err => {
        console.log(err)
      })
    },
    //상세 페이지 활성화
    moveDetail (messageIdSeq, tableId, groupId, catNm, ctr) {
      //상세활성화 토글
      this.toggleChange(messageIdSeq)

      //초기화
      $('td > a').removeClass('select')

      if (this.toggleValue) {
        //rest api 호출
        const detail = boards.getBoardsDetail(messageIdSeq, tableId, groupId, /*this.form.serviceCtrCd*/ctr)

        detail.then((data) => {
          const detailData = data.data

          detailData[0].createDate = this.$ekmtcCommon.changeDatePattern(detailData[0].createDate, '-') // 자식컴포넌트에서 사용할 date도 포맷팅 변경(read전용이기 때문에 다이렉트 변경)
          detailData[0].catNm = catNm
          detailData[0].tableCd = 'WB004'

          this.detail = detailData

          const list = this.items

          //볼드체 처리
          $('#' + messageIdSeq).addClass('select')

          //리스트 조회수 처리
          for (let item of list) {
            if (item.messageIdSeq === messageIdSeq) {
              item.hitCount = this.detail[0].hitCount
            }
          }
        })
      }
    },
    //검색
    search () {
      //검색조건 관련 셋팅
      const select = this.form.select

      if (select === 'all') {
        this.form.title = this.form.searchInput
        this.form.contents = this.form.searchInput
      } else if (select === 'title') {
        this.form.title = this.form.searchInput
        this.form.contents = ''
      } else if (select === 'contents') {
        this.form.title = ''
        this.form.contents = this.form.searchInput
      }

      this.getBoardsList(1, true)
    },
    backSearch (page, searchFlag) {
      //검색조건 관련 셋팅
      const select = this.form.select

      if (select === 'all') {
          this.form.title = this.form.searchInput
          this.form.contents = this.form.searchInput
      } else if (select === 'title') {
          this.form.title = this.form.searchInput
          this.form.contents = ''
      } else if (select === 'contents') {
          this.form.title = ''
          this.form.contents = this.form.searchInput
      }

      this.getBoardsList(page, true, false)
    },
    //등록일기준 +7일 이내인 경우 New Icon 보여주기
    isNewCheck (createDate) {
      //오늘날짜 - 작성일자
      let today = new Date()
      let formattingDay = new Date(this.$ekmtcCommon.changeDatePattern(createDate, '/'))
      let resultDay = (today - formattingDay) / (1000 * 60 * 60 * 24)

      return (resultDay <= 7)
    },
    //목록 및 타이틀 클릭시 상세 토글 기능('return' 값은 목록 클릭시 파라미터)
    toggleChange (messageIdSeq) {
      this.toggleValue = messageIdSeq
      window.scroll(0, 0)
    },
    // 카테고리 명 가져오기
    getCategoryName (cd) {
        let catNm = '카테고리 X'
        if (this.$ekmtcCommon.isNotEmpty(cd)) {
            this.categoryCdList.cdId.forEach((item) => {
              if (item.cd === cd) {
                  catNm = item.cdNm
              }
            })
        }
        return catNm
    }
  }
}
</script>

<style scoped>
 a {color: #000; text-decoration: none; cursor: pointer;}
</style>
