<template>
  <table class="tbl_list mt10">
    <colgroup>
      <col width="180px">
      <col width="120px">
      <col>
    </colgroup>
    <tbody>
      <tr>
        <td
          colspan="4"
          class="subject_view_tit"
        >
          {{ $ekmtcCommon.isNotEmpty(detail[0].catCd) ? '[' + $t(`code.${detail[0].tableCd}.${detail[0].catCd}`) + ']' : '' }}{{ detail[0].title }}
        </td>
      </tr>
      <tr class="subject_view_titinfo">
        <td class="text_left">
          <span class="tit">{{ $t('msg.KMTC410.007') }}</span><!-- 등록일 -->
          <span>{{ detail[0].createDate }}</span>
        </td>
        <td class="text_right">
          <span class="tit">{{ $t('msg.KMTC410.008') }}</span><!-- 조회수 -->
          <span>{{ detail[0].hitCount }}</span>
        </td>
      </tr>
      <tr>
        <td
          colspan="4"
          class="text_left"
        >
          <div
            class="subject_view_cont mb10"
            v-html="detail[0].contents"
          />
        </td>
      </tr>
      <tr v-if="detail[0].fileName">
        <td
          colspan="4"
          class="text_left"
        >
          <template v-for="(file,index) in detail">
            <div
              v-if="$ekmtcCommon.isNotEmpty(file.fileId) && file.fileId !== 0"
              :key="`file_${index}`"
              class="file_box"
            >
              <span class="tbl_icon attach">{{ $t('msg.KMTC410.006') }}</span><!-- 첨부 -->
              <span class="attach">
                <a
                  href="#"
                  @click.prevent="fileDownLoad(index)"
                >
                  {{ file.fileName }}
                </a>
              </span>
              <span>{{ computedFileSize(file.fileSize) }}</span>
              <!-- 인자가 있는 경우 computed보다 method 권장 -->
            </div>
          </template>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import boards from '@/api/services/boards'

export default {
  name: 'BoardsDetail',
  props: {
    detail: {
      default: function () {
        return []
      }
    }
  },
  methods: {
    computedFileSize (fileSize) {
      let n = Number(fileSize) / 1000
      n = n.toFixed(0)
      return (fileSize === 0 || fileSize === undefined) ? '' : ' (' + n + ' KBytes)'
    },
    fileDownLoad (index) {
      const formData = this.detail[index]

      boards.fileDownload(formData)
        .then(response => {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            //IE에서 동작
            window.navigator.msSaveOrOpenBlob(response.data, formData.fileName)
          } else {
            console.log(response.data)
            //CHROME에서 동작
            let blob = new Blob([response.data], { type: response.headers['content-type'] })
            const url = window.URL.createObjectURL(blob)
            const link = document.createElement('a')

            link.href = url
            link.setAttribute('download', formData.fileName)
            link.click()
            link.remove()

            window.URL.revokeObjectURL(url)
          }
        })
    }
  }
}
</script>
