<template>
  <div class="content">
    <e-breadcrumbs />
    <h1 class="page_title">{{ $t('msg.CSCT060G010.001') }}</h1><!-- Vessel -->
    <div class="content_box mt10">
      <!-- content_box -->
      <table class="tbl_search">
        <colgroup>
          <col width="4%">
          <col width="30%">
          <col width="32%">
        </colgroup>
        <tbody>
          <tr>
            <th>VSL</th>
            <td>
              <e-auto-complete-vessel @change="changeVsl" :cd="vsl.vslCd" :cd-nm="vsl.vslNm" />
            </td>
            <td>
              <a class="button blue sh" @click.prevent="getVslInfo()">{{ $t('msg.NEWB010P020.006') }}</a> <!-- 검색 -->
            </td>
          </tr>
        </tbody>
      </table>
    </div><!-- content_box // -->

    <div v-if="initFlag === 'N'" class="content_box mt10">
      <!-- content_box -->
      <div class="flex_box">
        <h2 class="content_title">{{ vesselInfoDetail.vslNm }}</h2>
      </div>
      <table class="tbl_row">
        <colgroup>
          <col width="150"><col>
          <col width="150"><col>
        </colgroup>
        <tbody>
          <tr>
            <th>{{ $t('msg.CSCT060G010.002') }}</th><!-- Vessel Type -->
            <td>{{ vesselInfoDetail.vslType }}</td>
            <th>{{ $t('msg.CSCT060G010.003') }}</th><!-- IMO No. -->
            <td>{{ vesselInfoDetail.imoNo }}</td>
          </tr>
          <tr>
            <th>{{ $t('msg.CSCT060G010.004') }}</th><!-- Vessel Owner -->
            <td>{{ vesselInfoDetail.vslOwner }}</td>
            <th>{{ $t('msg.CSCT060G010.005') }}</th><!-- Call Sign -->
            <td>{{ vesselInfoDetail.clsgNo }}</td>
          </tr>
          <tr>
            <th>{{ $t('msg.CSCT060G010.006') }}</th><!-- Built (Delivered) -->
            <td>{{ $ekmtcCommon.changeDatePattern(vesselInfoDetail.dlyDt) }}</td>
            <th>{{ $t('msg.CSCT060G010.007') }}</th><!-- Flag -->
            <td>{{ vesselInfoDetail.ctrEnm }}</td>
          </tr>
          <tr>
            <th>{{ $t('msg.CSCT060G010.008') }}</th><!-- Class -->
            <td>{{ vesselInfoDetail.clsfiNm }}</td>
            <th>{{ $t('msg.CSCT060G010.009') }}</th><!-- BREADTH -->
            <td>{{ vesselInfoDetail.breadth }}</td>
          </tr>
          <tr>
            <th>{{ $t('msg.CSCT060G010.010') }}</th><!-- DEAD WEIGHT TONNAGE -->
            <td>{{ $ekmtcCommon.changeNumberFormat(vesselInfoDetail.dwgtTons, { isComma: true }) }}</td>
            <th>{{ $t('msg.CSCT060G010.011') }}</th><!-- GROSS TONNAGE -->
            <td>{{ $ekmtcCommon.changeNumberFormat(vesselInfoDetail.grsWt, { isComma: true }) }}</td>
          </tr>
          <tr>
            <th>{{ $t('msg.CSCT060G010.012') }}</th><!-- CARGO CAPACITY -->
            <td>{{ vesselInfoDetail.nmnlTeu }}</td>
            <th>{{ $t('msg.CSCT060G010.013') }}</th><!-- NET REGISTER TONNAGE -->
            <td>{{ $ekmtcCommon.changeNumberFormat(vesselInfoDetail.netWt, { isComma: true }) }}</td>
          </tr>
          <tr>
            <th>{{ $t('msg.CSCT060G010.014') }}</th><!-- LEGTH OVER ALL -->
            <td>{{ vesselInfoDetail.loaLen }}</td>
            <th>{{ $t('msg.CSCT060G010.015') }}</th><!-- 선급증서 -->
            <td>
              <a
                v-if="$ekmtcCommon.isNotEmpty(vesselInfoDetail.vslCertiFileNm)"
                class="button sm"
                href="#"
                @click.prevent="certiFileDownload(vesselInfoDetail.atchFileRno)"
              >
                {{ $t('msg.CSCT060G010.016') }} <!-- PDF 다운로드-->
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div><!-- content_box // -->
  </div>
</template>

<script>
import vslScheduleInfo from '@/api/rest/schedule/vslScheduleInfo'

export default {
  name: 'VesselInfo',
  components: {
    vslScheduleInfo,
    EBreadcrumbs: () => import('@/components/common/EBreadcrumbs'),
    EAutoCompleteVessel: () => import('@/components/common/EAutoCompleteVessel'),
    EDatePicker: () => import('@/components/common/EDatePicker')
  },
  data: function () {
    return {
      params: {
        vslNm: '',
        vslCd: '',
        polPortCd: '',
        podPortCd: ''
      },
      //vsl 자동완성 데이터
      vsl: {
        ctrCd: '',
        vslCd: '',
        vslNm: ''
      },
      // 선박 정보
      vesselInfoDetail: {},
      // 선박/항차 스케줄 정보
      vesselScheduleInfo: [],
      //초기화면 Flag
      initFlag: 'Y'
    }
  },
  methods: {
    async getVslInfo () {
      if (this.vsl.vslNm !== undefined && this.vsl.vslNm !== '') {
        this.params.vslCd = this.vsl.vslCd
        this.params.vslNm = this.vsl.vslNm
        this.params.polPortCd = this.vsl.polPortCd
        this.params.podPortCd = this.vsl.podPortCd

        await vslScheduleInfo.getVslScheduleInfo(this.params)
          .then(response => {
            console.log(response)

            if (response.data.vesselInfoDetail) {
              // 선박 상세정보 세팅
              this.vesselInfoDetail = response.data.vesselInfoDetail

              if (this.$ekmtcCommon.isNotEmpty(this.vesselInfoDetail.breadth)) {
                this.vesselInfoDetail.breadth = this.vesselInfoDetail.breadth + ' M'
              }
              if (this.$ekmtcCommon.isNotEmpty(this.vesselInfoDetail.nmnlTeu)) {
                this.vesselInfoDetail.nmnlTeu = this.$ekmtcCommon.changeNumberFormat(this.vesselInfoDetail.nmnlTeu, { isComma: true }) + ' TEU'
              }
              if (this.$ekmtcCommon.isNotEmpty(this.vesselInfoDetail.loaLen)) {
                this.vesselInfoDetail.loaLen = this.$ekmtcCommon.changeNumberFormat(this.vesselInfoDetail.loaLen, { isComma: true }) + ' M'
              }
              // dlyDt가 8자리보다 작은경우
              if (this.vesselInfoDetail.dlyDt.length < 8) {
                let tmpDlyDt = this.vesselInfoDetail.dlyDt

                for (let i = 0; i < 8; i++) {
                  if (tmpDlyDt.length < 8) {
                    tmpDlyDt += '0'
                  }
                }
                this.vesselInfoDetail.dlyDt = tmpDlyDt
              }

              this.vesselInfoDetail.vslNmDisp = this.vsl.vslNm
              this.vesselInfoDetail.polTrmlCd = this.vsl.polTrmlCd

              this.initFlag = 'N'
            } else {
              //검색결과가 없을시 초기화면 셋팅
              this.$ekmtcCommon.alertDefault(this.$t('msg.CSCT060G010.018')) // 검색결과가 없습니다.

              this.initFlag = 'Y'
            }
          })
          .catch(error => {
            console.log(error)
          })
        } else {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSCT060G010.020')) // 검색어를 입력해주세요.
        }
    },
    changeVsl (vo) {
      this.vsl.vslNm = vo.cdNm
      this.vsl.vslCd = vo.cd
      console.log('>>>> [Vessel] vesselNm : ' + this.vsl.vslNm + ' / ' + this.vsl.vslCd)
    },
    // 선급증서 다운로드
    certiFileDownload (reqRno) {
      vslScheduleInfo.certiFileDownload(reqRno)
        .then(response => {
          console.log('response')
          console.log(response)
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            //IE에서 동작
            window.navigator.msSaveOrOpenBlob(response.data, this.vesselInfoDetail.vslCertiFileNm)
          } else {
            console.log(response.data)
            //CHROME에서 동작
            let blob = new Blob([response.data], { type: response.headers['content-type'] })
            const url = window.URL.createObjectURL(blob)
            const link = document.createElement('a')

            link.href = url
            link.setAttribute('download', this.vesselInfoDetail.vslCertiFileNm)
            link.click()
            link.remove()

            window.URL.revokeObjectURL(url)
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
