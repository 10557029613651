var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("e-breadcrumbs"),
      _c("h1", { staticClass: "page_title" }, [
        _vm._v(_vm._s(_vm.$t("msg.CSCT060G010.001"))),
      ]),
      _c("div", { staticClass: "content_box mt10" }, [
        _c("table", { staticClass: "tbl_search" }, [
          _vm._m(0),
          _c("tbody", [
            _c("tr", [
              _c("th", [_vm._v("VSL")]),
              _c(
                "td",
                [
                  _c("e-auto-complete-vessel", {
                    attrs: { cd: _vm.vsl.vslCd, "cd-nm": _vm.vsl.vslNm },
                    on: { change: _vm.changeVsl },
                  }),
                ],
                1
              ),
              _c("td", [
                _c(
                  "a",
                  {
                    staticClass: "button blue sh",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.getVslInfo()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("msg.NEWB010P020.006")))]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm.initFlag === "N"
        ? _c("div", { staticClass: "content_box mt10" }, [
            _c("div", { staticClass: "flex_box" }, [
              _c("h2", { staticClass: "content_title" }, [
                _vm._v(_vm._s(_vm.vesselInfoDetail.vslNm)),
              ]),
            ]),
            _c("table", { staticClass: "tbl_row" }, [
              _vm._m(1),
              _c("tbody", [
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060G010.002")))]),
                  _c("td", [_vm._v(_vm._s(_vm.vesselInfoDetail.vslType))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060G010.003")))]),
                  _c("td", [_vm._v(_vm._s(_vm.vesselInfoDetail.imoNo))]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060G010.004")))]),
                  _c("td", [_vm._v(_vm._s(_vm.vesselInfoDetail.vslOwner))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060G010.005")))]),
                  _c("td", [_vm._v(_vm._s(_vm.vesselInfoDetail.clsgNo))]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060G010.006")))]),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.$ekmtcCommon.changeDatePattern(
                          _vm.vesselInfoDetail.dlyDt
                        )
                      )
                    ),
                  ]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060G010.007")))]),
                  _c("td", [_vm._v(_vm._s(_vm.vesselInfoDetail.ctrEnm))]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060G010.008")))]),
                  _c("td", [_vm._v(_vm._s(_vm.vesselInfoDetail.clsfiNm))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060G010.009")))]),
                  _c("td", [_vm._v(_vm._s(_vm.vesselInfoDetail.breadth))]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060G010.010")))]),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.$ekmtcCommon.changeNumberFormat(
                          _vm.vesselInfoDetail.dwgtTons,
                          { isComma: true }
                        )
                      )
                    ),
                  ]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060G010.011")))]),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.$ekmtcCommon.changeNumberFormat(
                          _vm.vesselInfoDetail.grsWt,
                          { isComma: true }
                        )
                      )
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060G010.012")))]),
                  _c("td", [_vm._v(_vm._s(_vm.vesselInfoDetail.nmnlTeu))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060G010.013")))]),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.$ekmtcCommon.changeNumberFormat(
                          _vm.vesselInfoDetail.netWt,
                          { isComma: true }
                        )
                      )
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060G010.014")))]),
                  _c("td", [_vm._v(_vm._s(_vm.vesselInfoDetail.loaLen))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSCT060G010.015")))]),
                  _c("td", [
                    _vm.$ekmtcCommon.isNotEmpty(
                      _vm.vesselInfoDetail.vslCertiFileNm
                    )
                      ? _c(
                          "a",
                          {
                            staticClass: "button sm",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.certiFileDownload(
                                  _vm.vesselInfoDetail.atchFileRno
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("msg.CSCT060G010.016")) + " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "4%" } }),
      _c("col", { attrs: { width: "30%" } }),
      _c("col", { attrs: { width: "32%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150" } }),
      _c("col"),
      _c("col", { attrs: { width: "150" } }),
      _c("col"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }