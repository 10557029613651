<template>
  <div class="content">
    <e-breadcrumbs />
    <h1 class="page_title">{{ $t('menu.MENU09.040') }}</h1><!-- FAQ -->
    <div
      v-if="!toggleValue"
      class="content_box mt10"
    >
      <div class="mt5">
        <select
          v-model="param.catCd"
          class="wid110"
        >
          <option
            v-for="category in categoryList"
            :key="category.cd"
            :value="category.cd"
          >
            {{ $t(`code.WB005.${category.cd}`) }}
          </option>
        </select>
        <select
          v-model="param.select"
          class="wid110"
        >
          <option value="all">{{ $t('msg.CMBA100.00117') }}+{{ $t('msg.CMBA100.00119') }}</option><!-- 제목+내용 -->
          <option value="title">{{ $t('msg.CMBA100.00117') }}</option><!-- 제목 -->
          <option value="contents">{{ $t('msg.CMBA100.00119') }}</option><!-- 내용 -->
        </select>
        <input
          v-model="param.searchInput"
          type="text"
          class="wid300"
          @keyup.enter="search()"
        >
        <!-- 검색 -->
        <a
          class="button blue sh ml15"
          @click="search()"
        >
          {{ $t('msg.CMBA100.528') }}
        </a>
      </div>
    </div>
    <div class="content_box mt10">
      <BoardsDetail
        v-if="toggleValue"
        :detail="detail"
      />
      <!-- 글 개수 카운트 -->
      <div
        :class="['flex_box', toggleValue ? 'mt30': '']"
      >
        <!-- Total -->
        <span class="tot_num">{{ $t('msg.CMBA100.530') }} : <span class="num">{{ listCountNumber }}</span></span>
      </div>
      <table class="tbl_list font_sm">
        <colgroup>
          <col width="60px">
          <col width="80px">
          <col width="*">
          <col width="50px">
        </colgroup>
        <thead>
          <tr>
            <th>{{ $t('msg.CMBA100.531') }}</th><!-- 번호 -->
            <th>{{ $t('msg.CMBA100.527') }}</th><!-- 구분 -->
            <th>{{ $t('msg.CMBA100.00117') }}</th><!-- 제목 -->
            <th>{{ $t('msg.CMBA100.00141') }}</th><!-- 첨부 -->
          </tr>
        </thead>
        <tbody v-if="boardList.length > 0">
          <tr
            v-for="board in boardList"
            :key="board.messageIdSeq"
          >
            <td class="no">{{ board.num }}</td>
            <td class="category">
              {{ $ekmtcCommon.isNotEmpty(board.catCd) ? $t(`code.WB005.${board.catCd}`) : '' }}
            </td>
            <td class="subject">
              <a
                :id="board.messageIdSeq"
                :class="toggleValue === board.messageIdSeq ? 'select' : ''"
                @click.prevent="moveDetail(board.messageIdSeq, 'H', board.groupId, board.catNm)"
              >
                {{ board.title }}
              </a>
              <span
                v-if="board.isNew"
                class="tbl_icon new"
              />
            </td>
            <td>
              <a
                v-if="board.fileName"
                class="tbl_icon attach"
                @click.prevent="moveDetail(board.messageIdSeq, 'H', board.groupId, board.catNm)"
              >
                {{ $t('msg.CMBA100.00141') }}
              </a>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <!-- 등록된 내용이 없습니다. -->
            <td colspan="7">{{ $t('msg.CMBA100.00131') }}</td>
          </tr>
        </tbody>
      </table>
      <!-- paging -->
      <div
        v-if="boardList.length > 0"
        class="paging"
      >
        <a
          class="btn start"
          @click.prevent="getBoardsList(1, false)"
        >
          Start
        </a>
        <a
          class="btn prev"
          @click.prevent="getBoardsList(pagination.prevPage, false)"
        >
          Prev
        </a>
        <a
          v-for="idx in pageNumberRange"
          :key="idx"
          :class="[idx === param.curPage ? 'on' : '']"
          @click.prevent="getBoardsList(idx, false)"
        >
          {{ idx }}
        </a>
        <a
          class="btn next"
          @click.prevent="getBoardsList(pagination.nextPage, false)"
        >
          Next
        </a>
        <a
          class="btn end"
          @click.prevent="getBoardsList(pagination.pageCnt, false)"
        >
          End
        </a>
      </div>
      <!-- paging // -->
    </div>
    <!-- 목록 버튼 -->
    <div
      v-if="toggleValue"
      class="mt10 text_right"
    >
      <!-- w -->
      <a
        class="button blue lg"
        href="#"
        @click.prevent="toggleChange('')"
      >
        {{ $t('msg.CMBA100.529') }}
      </a>
    </div>
  </div>
</template>

<script>

import boards from '@/api/services/boards'
import BoardsDetail from '@/pages/common/BoardsDetail'
import EBreadcrumbs from '@/components/common/EBreadcrumbs'

var eventFunc
window.onpopstate = function (event) {
  eventFunc = event
}

export default {
  name: 'FaqHome',
  components: {
    BoardsDetail,
    EBreadcrumbs
  },
  data: function () {
    return {
      // 최초 API(FAQ 리스트) 호출여부
      isInit: false,

      param: {
        // 현재 페이지
        curPage: 1,
        // 한 페이지당 게시글 수
        pageSize: 15,
        // 테이블 ID [공지사항(A), 자료실(C), FAQ(H)]
        tableId: 'H',
        // 카테고리 구분 [구분 전체(all), 카테고리 1(01), 카테고리 2(02), 카테고리 3(03)]
        catCd: 'all',
        // 검색 구분 [제목+내용(all), 제목(title), 내용(contents)]
        select: 'all',
        // 검색 키워드
        searchInput: '',
        // 제목 키워드
        title: '',
        // 내용 키워드
        contents: '',
        // 접속 국가
        serviceCtrCd: ''
      },

      // 카테고리 리스트 [카테고리 1(01), 카테고리 2(02), 카테고리 3(03)]
      categoryList: [],

      // Total(전체 글 개수)
      listCountNumber: 0,
      // FAQ 리스트
      boardList: [],
      // 페이징 속성
      pagination: {},
      // 상세
      detail: [{ catNm: '카테고리 X' }],
      // 상세 ON/OFF FLAG
      toggleValue: '',
      handlebackBtn: true,
      pageHistory: [],
      pageCnt: 0,
      backBtn: false
    }
  },
  computed: {
    // 페이지 Number
    pageNumberRange: function () {
      return [...Array(this.pagination.pageNumberSize).keys()].map(i => i + this.pagination.startPage)
    }
  },
  mounted () {
    $('.header').click(function (e) {
      // console.log('header click @@@@@')
      vmApp.handlebackBtn = false
    })

    $('.quick_wrap').click(function (e) {
      // console.log('quick_wrap click @@@@@')
      vmApp.handlebackBtn = false
    })

    $('.content').click(function (e) {
      // console.log('content click @@@@@')
      vmApp.handlebackBtn = true
    })
  },
  beforeRouteLeave (to, from, next) {
    if (eventFunc !== undefined) {
        eventFunc = undefined
    }

    var pageLen = this.pageHistory.length
    var bfPage = pageLen - 2
    var curPage = pageLen - 1

    setTimeout(() => {
      window.onpopstate = function (event) {
        eventFunc = event
      }
    }, 200)

    setTimeout(() => {
      if (eventFunc !== undefined) {
        if (!this.$ekmtcCommon.isEmpty(this.toggleValue) && this.handlebackBtn) {
          history.pushState(null, null, location.pathname + '#/common/faq')
          this.toggleChange('')
        } else {
          if (pageLen > 1 && this.handlebackBtn) {
              history.pushState(null, null, location.pathname + '#/common/faq')

              // FAQ 안에서 이전페이지 이동
              if (this.pageHistory[bfPage] !== undefined) {
                if (this.pageHistory[bfPage].searchInput !== '') {
                  this.param.searchInput = this.pageHistory[bfPage].searchInput
                  this.backSearch(this.pageHistory[bfPage].page, true)
                } else {
                  this.param.searchInput = ''
                  this.backSearch(this.pageHistory[bfPage].page, false)
                }

                this.toggleChange('')

                this.pageHistory.splice(Number(curPage), 1)
              }
          } else {
            next()
          }
        }
      } else {
        if (!this.$ekmtcCommon.isEmpty(this.toggleValue) && this.handlebackBtn) {
          history.pushState(null, null, location.pathname + '#/common/faq')
          this.toggleChange('')
        } else {
          if (pageLen > 1 && this.handlebackBtn) {
              history.pushState(null, null, location.pathname + '#/common/faq')

              // FAQ 안에서 이전페이지 이동
              if (this.pageHistory[bfPage] !== undefined) {
                if (this.pageHistory[bfPage].searchInput !== '') {
                  this.param.searchInput = this.pageHistory[bfPage].searchInput
                  this.backSearch(this.pageHistory[bfPage].page, true)
                } else {
                  this.param.searchInput = ''
                  this.backSearch(this.pageHistory[bfPage].page, false)
                }

                this.toggleChange('')

                this.pageHistory.splice(Number(curPage), 1)
              }
          } else {
            next()
          }
        }
      }
    }, 300)
  },
  created () {
    window.vmApp = this

    // 접속국가 가져오기
    this.param.serviceCtrCd = localStorage.getItem('service_ctrcd')

    if (Object.keys(this.$route.query).length > 0) {
      const vo = this.$route.query

      // 통합검색 결과 페이지에서 들어오는 경우 - 상세 페이지까지 진입해야 함.
      if (vo.searchInput !== undefined && this.$ekmtcCommon.isNotEmpty(vo.searchInput)) {
        this.param.searchInput = vo.searchInput

        this.param.title = this.param.searchInput
        this.param.contents = this.param.searchInput
      }

      this.getCategoryList('WB005')
      if (vo.num !== undefined && this.$ekmtcCommon.isNotEmpty(vo.num)) {
        this.getBoardsList(Math.ceil(Number(vo.num) / Number(this.param.pageSize)), true)
      } else {
        this.getBoardsList(1, true)
      }

      this.moveDetail(vo.messageIdSeq, 'H', vo.groupId, vo.catNm)
    } else {
      // Defualt
      this.getCategoryList('WB005')
      this.getBoardsList(1)
    }
  },
  methods: {
    getCategoryList (cdId) {
      boards.getCode({ cdId: cdId })
        .then(response => {
          this.categoryList = [{ cd: 'all', cdNm: this.$t('msg.PERS010L022.002') }] // 구분 전체

          if (response.status === 200) {
            this.categoryList = [...this.categoryList, ...response.data]
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    search () {
      const select = this.param.select

      //검색조건 관련 셋팅
      if (select === 'all') {
        this.param.title = this.param.searchInput
        this.param.contents = this.param.searchInput
      } else if (select === 'title') {
        this.param.title = this.param.searchInput
        this.param.contents = ''
      } else if (select === 'contents') {
        this.param.title = ''
        this.param.contents = this.param.searchInput
      }

      this.getBoardsList(1, true)
    },
    backSearch (page, searchFlag) {
      // this.backBtn = true

      //검색조건 관련 셋팅
      const select = this.param.select

      if (select === 'all') {
          this.param.title = this.param.searchInput
          this.param.contents = this.param.searchInput
      } else if (select === 'title') {
          this.param.title = this.param.searchInput
          this.param.contents = ''
      } else if (select === 'contents') {
          this.param.title = ''
          this.param.contents = this.param.searchInput
      }

      this.getBoardsList(page, true, false)
    },
    /**
     * param
     * |_ index : 이동할 페이지
     * |_ searcgFlag : 검색유무
     *          true  => 검색 시
     *          false => 페이지 이동
     */
    getBoardsList (index, searchFlag, backFlag) {
      if (this.$ekmtcCommon.isEmpty(backFlag)) {
        const data = {
            idx: this.pagination.pageCnt,
            page: index,
            searchInput: this.param.searchInput
        }
        this.pageHistory.push(data)
        this.backBtn = false
      } else {
        this.backBtn = true
      }

      if (Object.keys(this.$route.query).length > 0) {
        this.$router.replace({ query: {} })
      }

      const alertOpt = {
        alertType: 'simple',
        customCloseBtnText: this.$t('msg.CMBA100.546'), // 닫기
        useConfirmBtn: false
      }

      if (this.isInit && index < 1) {
        this.$ekmtcCommon.alert({
          ...alertOpt, message: this.$t('js.common.002') // 처음페이지입니다.
        })
        return
      }
      if (this.isInit && this.pagination && index > this.pagination.pageCnt && !this.backBtn) {
        this.$ekmtcCommon.alert({
          ...alertOpt, message: this.$t('js.common.001') // 마지막페이지입니다.
        })
        return
      }

      // !searchFlag(페이지 이동 시) 현재 페이지와 이동할 페이지 같다면
      if (!searchFlag && this.isInit && this.param.curPage === index) return

      this.param.curPage = index

      boards.getBoardsList(this.param)
        .then(response => {
          const resp = response.data

          const list = resp.boardList
          const pagination = resp.pagination

          // FAQ 리스트 초기화
          this.boardList = []
          // 전체 글 개수 초기화
          this.listCountNumber = 0

          if (list !== undefined && list.length > 0) {
            // api response 값 data에 세팅
            this.pagination = pagination

            //전체 글 개수
            this.listCountNumber = list.length === 0 ? 0 : list[0].listCnt

            list.forEach((item) => {
              //new icon 타이틀 여부 체크 후 리스트에 담기
              item.isNew = this.isNewCheck(item.createDate)
              item.catNm = this.getCategoryName(item.catCd)

              this.boardList.push(item)
            })

            if (!this.isInit) this.isInit = true
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    //상세 페이지 활성화
    moveDetail (messageIdSeq, tableId, groupId, catNm) {
      //상세 활성화 토글
      this.toggleChange(messageIdSeq)

      //초기화
      $('td > a').removeClass('select')

      if (this.toggleValue) {
        //rest api 호출
        const detail = boards.getBoardsDetail(messageIdSeq, tableId, groupId, this.param.serviceCtrCd)

        detail.then((data) => {
          const detailData = data.data

          detailData[0].createDate = this.$ekmtcCommon.changeDatePattern(detailData[0].createDate, '-')
          detailData[0].catNm = catNm
          detailData[0].tableCd = 'WB005'

          this.detail = detailData

          const list = this.boardList

          //볼드체 처리
          $('#' + messageIdSeq).addClass('select')

          //리스트 조회수 처리
          for (let item of list) {
            if (item.messageIdSeq === messageIdSeq) {
              item.hitCount = this.detail.hitCount
            }
          }
        })
      }
    },
    //등록일기준 +7일 이내인 경우 New Icon 보여주기
    isNewCheck (createDate) {
      //오늘날짜 - 작성일자
      let today = new Date()
      let formattingDay = new Date(this.$ekmtcCommon.changeDatePattern(createDate, '/'))
      let resultDay = (today - formattingDay) / (1000 * 60 * 60 * 24)

      return (resultDay <= 7)
    },
    //목록 및 타이틀 클릭시 상세 토글 기능('return' 값은 목록 클릭시 파라미터)
    toggleChange (messageIdSeq) {
      this.toggleValue = messageIdSeq
      window.scroll(0, 0)
    },
    // 카테고리 명 가져오기
    getCategoryName (cd) {
      let catNm = '카테고리 X'
      if (this.$ekmtcCommon.isNotEmpty(cd)) {
        this.categoryList.forEach((item) => {
          if (item.cd === cd) {
            catNm = item.cdNm
          }
        })
      }
      return catNm
    }
  }
}
</script>

<style scoped>
 a {color: #000; text-decoration: none; cursor: pointer;}
</style>
