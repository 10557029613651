import Send from '../../schedule.client.js'

//주석정의방법 : 컨트롤러 메소드명 - 메소드에 대한 간단 주석 - REST URI
export default {
  // getVslLocationInfo - 스케쥴 > 선박정보 및 스케줄 > 선박 위치 정보 - GET /schedule/leg/vsl-location-info
  getVslLocationInfo (params) {
    return Send({
      url: '/schedule/leg2/vsl-location-info',
      method: 'get',
      params: params
    })
  },
  // getAllVslLocationInfo - CP > 모든 선박 위치 정보 - GET /schedule/leg2/all-vsl-location-info
  getAllVslLocationInfo () {
    return Send({
      url: '/schedule/leg2/all-vsl-location-info',
      method: 'get'
    })
  },
  // getAllPortLocationInfo - CP > 모든 항구 위치 정보 - GET /schedule/leg2/all-port-location-info
  getAllPortLocationInfo () {
    return Send({
      url: '/schedule/leg2/all-port-location-info',
      method: 'get'
    })
  },
  // getPortDetailETA - CP > 항구내 스케줄상(ETA) 선박 정보 - GET /schedule/leg2/port-detail-ETA-info
  getPortDetailETA (params) {
    return Send({
      url: '/schedule/leg2/port-detail-ETA-info',
      method: 'get',
      params: params
    })
  },
  // getPortDetailETD - CP > 항구내 스케줄상(ETD) 선박 정보 - GET /schedule/leg2/port-detail-ETD-info
  getPortDetailETD (params) {
    return Send({
      url: '/schedule/leg2/port-detail-ETD-info',
      method: 'get',
      params: params
    })
  },
  // getPortAisETA - CP > 항구내 AIS상(ETA) 선박 정보 - GET /schedule/leg2/port-ais-ETA-info
  getPortAisETA (params) {
    return Send({
      url: '/schedule/leg2/port-ais-ETA-info',
      method: 'get',
      params: params
    })
  },
  // getPortAisETD - CP > 항구내 AIS상(ETD) 선박 정보 - GET /schedule/leg2/port-ais-ETD-info
  getPortAisETD (params) {
    return Send({
      url: '/schedule/leg2/port-ais-ETD-info',
      method: 'get',
      params: params
    })
  },
  // getVesselPreiousRoute - CP > 선박 지나온 루트 정보 - GET /schedule/leg2/previous-route
  getVesselPreiousRoute (params) {
    return Send({
      url: '/schedule/leg2/previous-route',
      method: 'get',
      params: params
    })
  },
  // getVesselNextRoute - CP > 선박 지나갈 루트 정보 - GET /schedule/leg2/next-route
  getVesselNextRoute (params) {
    return Send({
      url: '/schedule/leg2/next-route',
      method: 'get',
      params: params
    })
  },
    // getAllWeatherTyphoonInfo - 태풍 정보 - GET /schedule/leg2/all-weather-typhoon-info
    getAllWeatherTyphoonInfo () {
      return Send({
        url: '/schedule/leg2/all-weather-typhoon-info',
        method: 'get'
      })
    },
    // getAllOprInfo - 태풍 정보 - GET /schedule/leg2/all-opr-info
    getAllOprInfo () {
      return Send({
        url: '/schedule/leg2/all-opr-info',
        method: 'get'
      })
    }
}
