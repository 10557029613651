var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("table", { staticClass: "tbl_list mt10" }, [
    _vm._m(0),
    _c("tbody", [
      _c("tr", [
        _c("td", { staticClass: "subject_view_tit", attrs: { colspan: "3" } }, [
          _vm.detail.suprYn == "Y"
            ? _c("span", { staticClass: "label important" }, [
                _vm._v(_vm._s(_vm.$t("msg.CMBA100.533"))),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("span", { staticClass: "cate" }, [
            _vm._v(
              _vm._s(
                _vm.$ekmtcCommon.isNotEmpty(_vm.detail.catCd)
                  ? "[" + _vm.$t(`code.WB003.${_vm.detail.catCd}`) + "]"
                  : ""
              )
            ),
          ]),
          _c("span", { class: [_vm.detail.suprYn == "Y" ? "important" : ""] }, [
            _vm._v(" " + _vm._s(_vm.detail.title)),
          ]),
        ]),
      ]),
      _c("tr", { staticClass: "subject_view_titinfo" }, [
        _c("td", { staticClass: "text_left" }, [
          _c("span", { staticClass: "tit" }, [
            _vm._v(_vm._s(_vm.$t("msg.CMBA100.00126"))),
          ]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.detail.createDate))]),
        ]),
        _vm._v(" "),
        _c("td", { staticClass: "text_left" }, [
          _c("span", { staticClass: "tit bar" }, [
            _vm._v(_vm._s(_vm.$t("msg.CMBA100.532"))),
          ]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.detail.lstUpdDtm))]),
        ]),
        _vm._v(" "),
        _c("td", { staticClass: "text_right" }, [
          _c("span", { staticClass: "tit" }, [
            _vm._v(_vm._s(_vm.$t("msg.KMTC410.008"))),
          ]),
          _c("span", [_vm._v(_vm._s(_vm.detail.hitCount))]),
        ]),
      ]),
      _c("tr", [
        _c("td", { staticClass: "text_left", attrs: { colspan: "3" } }, [
          _c("div", {
            staticClass: "subject_view_cont mb10",
            domProps: { innerHTML: _vm._s(_vm.detail.content) },
          }),
        ]),
      ]),
      _vm.detailFile[0]
        ? _c("tr", [
            _c(
              "td",
              { staticClass: "text_left", attrs: { colspan: "3" } },
              _vm._l(_vm.detailFile, function (file, seq) {
                return _c("div", { key: seq, staticClass: "file_box" }, [
                  _c("span", { staticClass: "tbl_icon attach" }, [
                    _vm._v(_vm._s(_vm.$t("msg.CMBA100.00141"))),
                  ]),
                  _c("span", { staticClass: "attach" }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.fileDownLoad(seq)
                          },
                        },
                      },
                      [_vm._v(_vm._s(file.fileName))]
                    ),
                  ]),
                  _c("span", [
                    _vm._v(_vm._s(_vm.computedFileSize(file.fileSize))),
                  ]),
                ])
              }),
              0
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "180px" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "120px" } }),
      _c("col"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }