<template>
  <table class="tbl_list mt10">
    <colgroup>
      <col width="180px">
      <col width="*">
      <col width="120px">
      <col>
    </colgroup>
    <tbody>
      <tr>
        <td colspan="3" class="subject_view_tit">
          <span v-if="detail.suprYn == 'Y'" class="label important">{{ $t('msg.CMBA100.533') }}</span> <!-- 중요 -->
          <span class="cate">{{ $ekmtcCommon.isNotEmpty(detail.catCd) ? '[' + $t(`code.WB003.${detail.catCd}`) + ']' : '' }}</span>
          <span :class="[detail.suprYn == 'Y' ? 'important' : '']"> {{ detail.title }}</span>
        </td>
      </tr>
      <tr class="subject_view_titinfo">
        <td class="text_left"><span class="tit">{{ $t('msg.CMBA100.00126') }}</span> <span>{{ detail.createDate }}</span></td> <!-- 등록일 -->
        <td class="text_left"><span class="tit bar">{{ $t('msg.CMBA100.532') }}</span> <span>{{ detail.lstUpdDtm }}</span></td> <!-- 최종 수정일 -->
        <td class="text_right"><span class="tit">{{ $t('msg.KMTC410.008') }}</span><span>{{ detail.hitCount }}</span></td> <!-- 조회수 -->
      </tr>
      <tr>
        <td colspan="3" class="text_left">
          <div class="subject_view_cont mb10" v-html="detail.content"></div>
        </td>
      </tr>
      <tr v-if="detailFile[0]">
        <td colspan="3" class="text_left">
          <div class="file_box" v-for="(file,seq) in detailFile" :key="seq">
            <span class="tbl_icon attach">{{ $t('msg.CMBA100.00141') }}</span>
            <span class="attach"><a href="#" @click.prevent="fileDownLoad(seq)">{{ file.fileName }}</a></span>
            <span>{{ computedFileSize(file.fileSize) }}</span>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import notices from '@/api/services/notices'
import { rootComputed } from '@/store/helpers'

export default {
    name: 'NoticesDetail',
  components: {},
    props: ['detail', 'detailFile'],
    computed: {
      ...rootComputed
    },
    methods: {
      computedFileSize (fileSize) {
        console.log('@@@@ fileSize: ', fileSize)
        let n = Number(fileSize) / 1000
        n = n.toFixed(0)
        return (fileSize === 0 || fileSize === undefined) ? '' : ' (' + n + ' KBytes)'
      },
      fileDownLoad (index) {
      const formData = this.detailFile[index]

      //formData.lang = this.auth.serviceLang
      formData.lang = formData.ctrCd
      formData.userCtrCd = (this.memberDetail === undefined) ? 'KR' : this.memberDetail.userCtrCd

      notices.fileDownload(formData)
        .then(response => {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            //IE에서 동작
            window.navigator.msSaveOrOpenBlob(response.data, formData.fileName)
          } else {
            console.log(response.data)
            //CHROME에서 동작
            let blob = new Blob([response.data], { type: response.headers['content-type'] })
            const url = window.URL.createObjectURL(blob)
            const link = document.createElement('a')

            link.href = url
            link.setAttribute('download', formData.fileName)
            link.click()
            link.remove()

            window.URL.revokeObjectURL(url)
          }
        })
    }
  }
}
</script>
