var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("table", { staticClass: "tbl_list mt10" }, [
    _vm._m(0),
    _c("tbody", [
      _c("tr", [
        _c("td", { staticClass: "subject_view_tit", attrs: { colspan: "4" } }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$ekmtcCommon.isNotEmpty(_vm.detail[0].catCd)
                  ? "[" +
                      _vm.$t(
                        `code.${_vm.detail[0].tableCd}.${_vm.detail[0].catCd}`
                      ) +
                      "]"
                  : ""
              ) +
              _vm._s(_vm.detail[0].title) +
              " "
          ),
        ]),
      ]),
      _c("tr", { staticClass: "subject_view_titinfo" }, [
        _c("td", { staticClass: "text_left" }, [
          _c("span", { staticClass: "tit" }, [
            _vm._v(_vm._s(_vm.$t("msg.KMTC410.007"))),
          ]),
          _c("span", [_vm._v(_vm._s(_vm.detail[0].createDate))]),
        ]),
        _c("td", { staticClass: "text_right" }, [
          _c("span", { staticClass: "tit" }, [
            _vm._v(_vm._s(_vm.$t("msg.KMTC410.008"))),
          ]),
          _c("span", [_vm._v(_vm._s(_vm.detail[0].hitCount))]),
        ]),
      ]),
      _c("tr", [
        _c("td", { staticClass: "text_left", attrs: { colspan: "4" } }, [
          _c("div", {
            staticClass: "subject_view_cont mb10",
            domProps: { innerHTML: _vm._s(_vm.detail[0].contents) },
          }),
        ]),
      ]),
      _vm.detail[0].fileName
        ? _c("tr", [
            _c(
              "td",
              { staticClass: "text_left", attrs: { colspan: "4" } },
              [
                _vm._l(_vm.detail, function (file, index) {
                  return [
                    _vm.$ekmtcCommon.isNotEmpty(file.fileId) &&
                    file.fileId !== 0
                      ? _c(
                          "div",
                          { key: `file_${index}`, staticClass: "file_box" },
                          [
                            _c("span", { staticClass: "tbl_icon attach" }, [
                              _vm._v(_vm._s(_vm.$t("msg.KMTC410.006"))),
                            ]),
                            _c("span", { staticClass: "attach" }, [
                              _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.fileDownLoad(index)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(file.fileName) + " ")]
                              ),
                            ]),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.computedFileSize(file.fileSize))
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "180px" } }),
      _c("col", { attrs: { width: "120px" } }),
      _c("col"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }