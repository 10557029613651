<template>
  <div class="content">
    <!-- content : 우측quick_wrap 닫히면 resize 제거 -->
    <div>
      <e-breadcrumbs />

      <h1 class="page_title">
        {{ $t('menu.MENU09.010') }}
      </h1>  <!--공지사항 -->

      <!-- 검색 박스 -->
      <div
        v-if="!toggleValue"
        class="content_box mt10"
      >
        <div class="mt5">
          <select
            v-model="param.catCd"
            class="wid110"
          >
            <option
              v-for="category in categoryList"
              :key="category.cd"
              :value="category.cd"
            >
              {{ $t(`code.WB003.${category.cd}`) }}
            </option>
          </select>
          <select
            v-model="param.select"
            class="wid110"
          >
            <option value="all">
              {{ $t('msg.CMBA100.00117') }} + {{ $t('msg.CMBA100.00119') }}
            </option> <!-- 제목+내용 -->
            <option value="title">
              {{ $t('msg.CMBA100.00117') }}
            </option> <!-- 제목 -->
            <option value="contents">
              {{ $t('msg.CMBA100.00119') }}
            </option> <!-- 내용 -->
          </select>
          <input
            v-model="param.searchInput"
            type="text"
            class="wid300"
            @keyup.enter="search()"
          >
          <a
            href="#"
            class="button blue sh ml15"
            @click.prevent="search()"
          >{{ $t('msg.CMBA100.528') }}</a> <!-- 검색 -->
        </div>
      </div>

      <!-- 글 리스트 카운트 및 목록 -->
      <div class="content_box mt10">
        <NoticesDetail
          v-if="toggleValue"
          :detail="detail"
          :detail-file="detailFile"
        />
        <!-- 글 개수 카운트 -->
        <div
          :class="[!toggleValue ? 'flex_box' : 'flex_box mt30']"
        >
          <span class="tot_num">
            {{ $t('msg.CMBA100.530') }} : <span class="num">{{ listCountNumber }}</span></span>
        </div>

        <!-- 글 리스트 -->
        <table class="tbl_list font_sm">
          <colgroup>
            <col width="60px">
            <col width="80px">
            <col width="*">
            <col width="50px">
            <col width="80px">
            <col width="80px">
            <col width="70px">
            <col>
          </colgroup>
          <thead>
            <tr>
              <th>{{ $t('msg.CMBA100.531') }}</th> <!-- 번호 -->
              <th>{{ $t('msg.CMBA100.527') }}</th> <!-- 구분 -->
              <th>{{ $t('msg.KMTC410.005') }}</th> <!-- 제목 -->
              <th>{{ $t('msg.CMBA100.00141') }}</th> <!-- 첨부 -->
              <th>{{ $t('msg.KMTC410.007') }}</th> <!-- 등록일 -->
              <th>{{ $t('msg.CMBA100.532') }}</th> <!-- 최종 수정일 -->
              <th>{{ $t('msg.KMTC410.008') }}</th> <!-- 조회수 -->
            </tr>
          </thead>
          <tbody v-if="noticeList.length > 0">
            <tr
              v-for="notice in noticeList"
              :key="notice.noticeId"
            >
              <td class="no">
                <span
                  v-if="notice.suprYn == 'Y'"
                  class="label important"
                >{{ $t('msg.CMBA100.533') }}</span> <!-- 중요 -->
                <span v-if="notice.suprYn == 'N'">{{ notice.num }}</span>
              </td>
              <td class="category">
                {{ $ekmtcCommon.isNotEmpty(notice.catCd) ? $t(`code.WB003.${notice.catCd}`) : '' }}
              </td>
              <td :class="[notice.suprYn == 'Y' ? 'subject important' : 'subject']">
                <a
                  :id="notice.noticeId"
                  :class="toggleValue === notice.noticeId ? 'select' : ''"
                  @click.prevent="moveDetail(notice.noticeId, 'A', notice.catNm , notice.ctrCd)"
                >{{ notice.title }}</a>
                <span
                  v-if="notice.isNew"
                  class="tbl_icon new"
                />
              </td>
              <td>
                <a
                  :class="[notice.fileExt == 'Y' ? 'tbl_icon attach' : 'tbl_icon']"
                  @click.prevent="moveDetail(notice.noticeId, 'A', notice.catNm, notice.ctrCd)"
                >{{ $t('msg.CMBA100.00141') }}</a>
              </td>
              <td class="date">
                {{ $ekmtcCommon.changeDatePattern(notice.createDate, '-') }}
              </td> <!-- 인자가 있는 경우 computed보다 method 권장 -->
              <td class="udate">
                {{ $ekmtcCommon.changeDatePattern(notice.lstUpdDtm, '-') }}
              </td>
              <td class="hits">
                {{ notice.hitCount }}
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="7">
                {{ $t('msg.CMBA100.00131') }}
              </td> <!-- 등록된 내용이 없습니다. -->
            </tr>
          </tbody>
        </table>

        <!-- paging -->
        <div
          v-if="noticeList.length > 0"
          class="paging"
        >
          <a
            href="#"
            class="btn start"
            @click.prevent="getNoticesList(1, false)"
          >Start</a>
          <a
            href="#"
            class="btn prev"
            @click.prevent="getNoticesList(pagination.prevPage, false)"
          >Prev</a>
          <a
            href="#"
            v-for="idx in pageNumberRange"
            :key="idx"
            :class="[idx === param.curPage ? 'on' : '']"
            @click.prevent="getNoticesList(idx, false)"
          >{{ idx }}</a>
          <a
            href="#"
            class="btn next"
            @click.prevent="getNoticesList(pagination.nextPage, false)"
          >Next</a>
          <a
            href="#"
            class="btn end"
            @click.prevent="getNoticesList(pagination.pageCnt, false)"
          >End</a>
        </div>
      <!-- paging // -->
      </div>
      <!-- 목록 버튼 -->
      <div v-if="toggleValue" class="mt10 text_right">
        <a
          class="button blue lg"
          @click="toggleChange('')"
        >
          {{ $t('msg.CMBA100.529') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>

import notices from '@/api/services/notices'
import NoticesDetail from '@/pages/common/NoticesDetail'
import EBreadcrumbs from '@/components/common/EBreadcrumbs'
import { rootComputed } from '@/store/helpers'

var eventFunc
window.onpopstate = function (event) {
  eventFunc = event
}

export default {
  name: 'Notices',
  components: {
    NoticesDetail,
    EBreadcrumbs
  },
  data: function () {
    return {
      isInit: false,
      param: {
        curPage: 1, // 현재 페이지
        pageSize: 15, // 한 페이지당 게시글 수
        tableId: 'A',
        catCd: 'all',
        select: 'all',
        searchInput: '',
        title: '',
        contents: '',
        lang: '',
        serviceCtrCd: ''

      },

      categoryList: [],

      listCountNumber: 0,
      noticeList: [],
      pagination: {},
      detail: [],
      detailFile: [],
      toggleValue: '',
      pageHistory: [],
      pageCnt: 0,
      pageCick: false,
      detailClick: false,

      handlebackBtn: true,
      backBtn: false
    }
  },
  computed: {
    ...rootComputed,
    pageNumberRange: function () {
        return [...Array(this.pagination.pageNumberSize).keys()].map(i => i + this.pagination.startPage)
    },
    checkClick () {
      return $('a').attr('href')
    }
  },
  watch: {
  },
  mounted () {
    $('.header').click(function (e) {
      // console.log('header click @@@@@')
      vmApp.handlebackBtn = false
    })

    $('.quick_wrap').click(function (e) {
      // console.log('quick_wrap click @@@@@')
      vmApp.handlebackBtn = false
    })

    $('.content').click(function (e) {
      // console.log('content click @@@@@')
      vmApp.handlebackBtn = true
    })
  },
  beforeRouteLeave (to, from, next) {
    if (eventFunc !== undefined) {
        eventFunc = undefined
    }

    var pageLen = this.pageHistory.length
    var bfPage = pageLen - 2
    var curPage = pageLen - 1

    setTimeout(() => {
      window.onpopstate = function (event) {
        eventFunc = event
      }
    }, 200)

    setTimeout(() => {
      if (eventFunc !== undefined) {
        if (!this.$ekmtcCommon.isEmpty(this.toggleValue) && this.handlebackBtn) {
          history.pushState(null, null, location.pathname + '#/common/notice')
          this.toggleChange('')
        } else {
          if (pageLen > 1 && this.handlebackBtn) {
              history.pushState(null, null, location.pathname + '#/common/notice')

              // 공지사항 안에서 이전페이지 이동
              if (this.pageHistory[bfPage] !== undefined) {
                if (this.pageHistory[bfPage].searchInput !== '') {
                  this.param.searchInput = this.pageHistory[bfPage].searchInput
                  this.backSearch(this.pageHistory[bfPage].page, true)
                } else {
                  this.param.searchInput = ''
                  this.backSearch(this.pageHistory[bfPage].page, false)
                }

                this.toggleChange('')
                this.pageHistory.splice(Number(curPage), 1)
              }
          } else {
            next()
          }
        }
      } else {
            if (!this.$ekmtcCommon.isEmpty(this.toggleValue) && this.handlebackBtn) {
              history.pushState(null, null, location.pathname + '#/common/notice')
              this.toggleChange('')
            } else {
              if (pageLen > 1 && this.handlebackBtn) {
                  history.pushState(null, null, location.pathname + '#/common/notice')

                  // 공지사항 안에서 이전페이지 이동
                  if (this.pageHistory[bfPage] !== undefined) {
                    if (this.pageHistory[bfPage].searchInput !== '') {
                      this.param.searchInput = this.pageHistory[bfPage].searchInput
                      this.backSearch(this.pageHistory[bfPage].page, true)
                    } else {
                      this.param.searchInput = ''
                      this.backSearch(this.pageHistory[bfPage].page, false)
                    }

                    this.toggleChange('')
                    this.pageHistory.splice(Number(curPage), 1)
                  }
              } else {
                next()
              }
            }
          }
        }, 300)
  },
  created () {
    window.vmApp = this

    // 접속국가 가져오기
    this.param.serviceCtrCd = this.auth.login ? this.memberDetail.userCtrCd : (() => {
      let ctrCd = ''
      if (localStorage.getItem('service_ctrcd') === 'EN') {
        ctrCd = 'AE'
      } else {
        ctrCd = localStorage.getItem('service_ctrcd')
      }
      return ctrCd
    })()

    if (Object.keys(this.$route.query).length > 0) {
      const vo = this.$route.query
      // console.log('@@@@ vo: ', vo)

      // 통합검색 결과 페이지에서 들어오는 경우 - 상세 페이지까지 진입해야 함.
      if (vo.searchInput !== undefined && this.$ekmtcCommon.isNotEmpty(vo.searchInput)) {
        this.param.searchInput = vo.searchInput

        this.param.title = this.param.searchInput
        this.param.contents = this.param.searchInput
      }

      this.getCategoryList('WB003')
      if (vo.num !== undefined && this.$ekmtcCommon.isNotEmpty(vo.num)) {
        this.getNoticesList(Math.ceil(Number(vo.num) / Number(this.param.pageSize)), true)
      } else {
        this.getNoticesList(1, true)
      }

      this.moveDetail(vo.noticeId, vo.tableId, vo.catNm, vo.ctrCd)
    } else {
      // Defualt
      this.getCategoryList('WB003')
      this.getNoticesList(1)
    }
  },
  methods: {
    getCategoryList (cdId) {
      notices.getCode({ cdId: cdId })
          .then(response => {
            this.categoryList = [{ cd: 'all', cdNm: this.$t('msg.PERS010L022.002') }]

            if (response.status === 200) {
              this.categoryList = [...this.categoryList, ...response.data]
            }
          })
          .catch(err => {
            console.log(err)
          })
    },
    search () {
      //검색조건 관련 셋팅
      const select = this.param.select

      if (select === 'all') {
          this.param.title = this.param.searchInput
          this.param.contents = this.param.searchInput
      } else if (select === 'title') {
          this.param.title = this.param.searchInput
          this.param.contents = ''
      } else if (select === 'contents') {
          this.param.title = ''
          this.param.contents = this.param.searchInput
      }

      this.getNoticesList(1, true)
    },
    backSearch (page, searchFlag) {
      // this.backBtn = true

      //검색조건 관련 셋팅
      const select = this.param.select

      if (select === 'all') {
          this.param.title = this.param.searchInput
          this.param.contents = this.param.searchInput
      } else if (select === 'title') {
          this.param.title = this.param.searchInput
          this.param.contents = ''
      } else if (select === 'contents') {
          this.param.title = ''
          this.param.contents = this.param.searchInput
      }

      this.getNoticesList(page, true, false)
    },
    /**
     * param
     * |_ index : 이동할 페이지
     * |_ searcgFlag : 검색유무
     *          true  => 검색 시
     *          false => 페이지 이동
     */
    getNoticesList (index, searchFlag, backFlag) {
      if (this.$ekmtcCommon.isEmpty(backFlag)) {
        this.pageCnt++

        const data = {
            idx: this.pagination.pageCnt,
            page: index,
            searchInput: this.param.searchInput
        }
        this.pageHistory.push(data)
        this.backBtn = false
      } else {
        this.backBtn = true
      }

      if (Object.keys(this.$route.query).length > 0) {
        this.$router.replace({ query: {} })
      }

      const alertOpt = {
        alertType: 'simple',
        customCloseBtnText: this.$t('msg.CMBA100.546'), // 닫기
        useConfirmBtn: false
      }

      if (this.isInit && index < 1) {
        this.$ekmtcCommon.alert({
          ...alertOpt, message: this.$t('js.common.002') // 처음페이지입니다.
        })
        return
      }

      if (this.isInit && this.pagination && index > this.pagination.pageCnt && !this.backBtn) {
        this.$ekmtcCommon.alert({
          ...alertOpt, message: this.$t('js.common.001') // 마지막페이지입니다.
        })
        return
      }

      // !searchFlag(페이지 이동 시) 현재 페이지와 이동할 페이지 같다면
      if (!searchFlag && this.isInit && this.param.curPage === index) return

      this.param.curPage = index

      // 첫페이지 일때, 페이지 사이즈 파라미터 숫자 크게
      if (this.param.curPage === 1) {
        this.param.pageSize = 50
      } else {
        this.param.pageSize = 15
      }

      let _param = {}
      //if (this.auth.login === false && localStorage.getItem('service_ctrcd') === 'EN') {
      if (localStorage.getItem('service_ctrcd') === 'EN') {
        _param = {
          ...this.param,
          serviceCtrCd: 'DUMMY'
        }
      } else {
        _param = {
          ...this.param,
          serviceCtrCd: localStorage.getItem('service_ctrcd')
        }
      }

      notices.getNoticesList(_param).then((response) => {
        const resp = response.data
        const list = resp.noticeList
        const pagination = resp.pagination
        let suprCnt = 0

        this.noticeList = []

        if (list !== undefined) {
          this.pagination = pagination

          //전체 글 개수
          this.listCountNumber = list.length === 0 ? 0 : list[0].listCnt

          list.forEach((item, idx) => {
            //new icon 타이틀 여부 체크 후 리스트에 담기
            item.isNew = this.isNewCheck(item.lstUpdDtm)
            item.catNm = this.getCategoryName(item.catCd)
            item.lstUpdDtm = String(item.lstUpdDtm).substring(0, 8)

            // 첫페이지 중요글 + 일반글 15개
            if (item.suprYn === 'Y') { // 중요글
              suprCnt += 1
              this.noticeList.push(item)
            }

            if (item.suprYn === 'N' && idx < (15 + suprCnt)) { // 일반글
              this.noticeList.push(item)
            }
          })

          if (!this.isInit) this.isInit = true
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        console.log(this.noticeList)
      })
    },
    //상세 페이지 활성화
    moveDetail (noticeId, tableId, catNm, ctr) {
      //상세활성화 토글
      this.toggleChange(noticeId)

      if (this.toggleValue) {
        //rest api 호출
        const detail = notices.getNoticesDetail(noticeId, tableId, ctr/*this.param.serviceCtrCd*/)

        detail.then((data) => {
          const detailData = data.data

          detailData.detail[0].createDate = this.$ekmtcCommon.changeDatePattern(detailData.detail[0].createDate, '-') // 자식컴포넌트에서 사용할 date도 포맷팅 변경(read전용이기 때문에 다이렉트 변경)
          detailData.detail[0].lstUpdDtm = this.$ekmtcCommon.changeDatePattern(String(detailData.detail[0].lstUpdDtm).substring(0, 8), '-') // 자식컴포넌트에서 사용할 date도 포맷팅 변경(read전용이기 때문에 다이렉트 변경)
          if (catNm) {
            detailData.detail[0].catNm = catNm
          } else {
            detailData.detail[0].catNm = this.getCategoryName(detailData.detail[0].catCd)
          }

          this.detail = detailData.detail[0]
          this.detailFile = detailData.detailFile

          const list = this.noticeList

          //리스트 조회수 처리
          for (let item of list) {
            if (item.noticeId === noticeId) {
              item.hitCount = this.detail.hitCount
            }
          }
        })
      }
    },
    //등록일기준 +7일 이내인 경우 New Icon 보여주기
    isNewCheck (createDate) {
      //오늘날짜 - 작성일자
      let today = new Date()
      let formattingDay = new Date(this.$ekmtcCommon.changeDatePattern(createDate, '/'))
      let resultDay = (today - formattingDay) / (1000 * 60 * 60 * 24)

      return (resultDay <= 7)
    },
    //목록 및 타이틀 클릭시 상세 토글 기능('return' 값은 목록 클릭시 파라미터)
    toggleChange (noticeId) {
      this.toggleValue = noticeId
      window.scroll(0, 0)
    },
    // 카테고리 명 가져오기
    getCategoryName (cd) {
      let catNm = '카테고리 X'
      if (this.$ekmtcCommon.isNotEmpty(cd)) {
          this.categoryList.forEach((item) => {
            if (item.cd === cd) {
                catNm = item.cdNm
            }
        })
      }
      return catNm
    }
  }
}
</script>

<style scoped>
 a {color: #000; text-decoration: none; cursor: pointer;}
</style>
